.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table,
table td,
table th {
  border: 1px solid black;
  border-collapse: collapse;
  cell-padding: 10px;
}

tr.internalIncidentCritical td {
  background-color: red;
  color: black;
}

tr.internalIncidentWarning td {
  background-color: yellow;
  color: black;
}

tr.scoringIncidentCritical td {
  background-color: peachpuff;
  color: black;
}

tr.scoringIncidentWarning td {
  background-color: lightyellow;
  color: black;
}

tr.incidentNormal td {
  background-color: #FFFFFF;
  color: black;
}

tr.fingerprintCritical td {
 background-color: peachpuff;
 color: black;
}

tr.fingerprintWarning td {
background-color: lightyellow;
color: black;
}

tr.fingerprintNormal td {
    background-color: #FFFFFF;
    color: black;
}

tr.sessionCritical td {
 background-color: red;
 color: black;
}

tr.sessionWarning td {
background-color: #FFFF99;
color: black;
}
